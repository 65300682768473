.rsvp-main-font {
    font-family: 'MoYangZhuanJi';
    font-size: 38px;
    color: white;
}

.rsvp-eng-font {
    font-family: 'Cambay';
    letter-spacing: 0.1em;
    color: white;
    font-size: 15px;
}

.rsvp-font-large {
  font-size: 25px;
  margin-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.rsvp-spacing {
  justify-content: center;
  gap: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: env(safe-area-inset-top, 20px);
  height: 100vh;
  position: relative;
}

.darken-background {
  position: relative;
}

.darken-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.25); /* Adjust the alpha for darker/lighter */
  z-index: 1;
}

.darken-background > * {
  position: relative;
  z-index: 2;
}

  
@media (min-width: 768px) {
  /* adjustment for large screen and */
  .background-rsvp-image {
      background-position: 0px calc(60%) !important;
  }
}