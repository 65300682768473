.top-spacing {
    padding-top: 5.5rem;
}

.divider-title {
    height: 3px;
    background-color: white;
    width: 50vw;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 25px;
  }

.text-details {
    font-family: 'Cambay';
    color: white;
    letter-spacing: 0.2em;
    font-size: 15px;
    text-transform: uppercase;
}

.invitation-details {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title-text {
    font-size: 20px;
}

.display-absolute {
    position: absolute !important;
    bottom: 3em;
}

.countdown-green {
  background-color: #424330;
}

.countdown-blue {
  background-color: #62839e;
}

.display-under-countdown {
    position: relative !important;
    padding-top: 2.5em;
}

.description-text {
    font-size: 12px;
}

.darken-background {
    position: relative;
  }
  
  .darken-background::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.25); /* Adjust the alpha for darker/lighter */
    z-index: 1;
  }
  
  .darken-background > * {
    position: relative;
    z-index: 2;
  }

  @media (min-width: 768px) {
    /* Adjustments for larger screens */
    .description-text {
      font-size: 14px;
    }


    .text-details {
      font-family: 'Cambay';
      color: white;
      letter-spacing: 0.2em;
      font-size: 15px;
      text-transform: uppercase;
  }

.title-text {
  font-size: 25px;
}

.divider-title {
  height: 3px;
  background-color: white;
  width: 30vw;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 25px;
}
  }